<template>
	<div>
		<div class="ft20 cl-black cl-main ftw500">会员列表</div>

		<div class="mt20">
			<div class="form-search-box ">
				<a-form layout="inline">
					<!-- <a-form-item label="会员昵称">
						<a-input v-model="search.nick_name" placeholder="请输入会员昵称"></a-input>
					</a-form-item> -->
					<a-form-item label="会员手机号">
						<a-input v-model="search.mobile" placeholder="请输入会员手机号"></a-input>
					</a-form-item>
					<a-form-item label="车牌">
						<a-input v-model="search.carNum" placeholder="请输入车牌"></a-input>
					</a-form-item>
					<!-- <a-form-item label="会员等级">
						<div>
							<a-select style="width: 120px" placeholder="请选择" v-model="search.level_id">
								<a-select-option :value="-99">请选择</a-select-option>
								<a-select-option :value="0">普通会员</a-select-option>
								<a-select-option :value="item.level_id" v-for="(item,index) in level" :key="index">{{item.name}}</a-select-option>
							</a-select>
						</div>
					</a-form-item> -->
					<!-- <a-form-item label="会员生日">
						<a-radio-group v-model="search.birthday_type" >
						        <a-radio-button :value="1">
						          全部
						        </a-radio-button>
						        <a-radio-button :value="2">
						          本月
						        </a-radio-button>
								<a-radio-button :value="3">
								  下月
								</a-radio-button>
						  </a-radio-group>
					</a-form-item> -->
					<a-form-item>
						<a-button @click="searchAct" type="primary">查询</a-button>
						<a-button @click="cancelAct" class="ml10">取消</a-button>
					</a-form-item>
				</a-form>
			</div>
		</div>

		<div class="mt20 pd20 bg-w">
			<!-- <div>
				<a-button type="primary" icon="export" @click="exportMemberAct()">导出会员</a-button>
			</div> -->
			<div class="wxb-table-gray mt20">
				<a-table rowKey="member_id" :columns="columns" :pagination="pagination" @change="handleTableChange" :data-source="datas"
				 :loading="loading">
					<div class="flex alcenter center" slot="vip" slot-scope="record,index">
						<img v-if="record.face!=null" :src="record.face" class="member-index-face" />
						<img v-else src="../../assets/image/woyaoyou_head_defult@2x.png" class="member-index-face" />
						<div class="ml5" style="text-align: left;">
							<div>{{record.nick_name}}</div>
							<div class="mt2">{{record.mobile}}</div>
						</div>
					</div>
					
					<div class="flex alcenter center" slot="pmember" slot-scope="pmember,record">
						{{pmember==null ? '暂无' : pmember.nick_name}}
					</div>
					
					<div class="flex alcenter center" slot="level" slot-scope="level,record">
						{{level==null ? '零级会员' : level.name}}
					</div>
					
					<div class="flex alcenter center" slot="balance" slot-scope="balance,record">
						¥{{balance}}
					</div>
					<div class="flex alcenter center" slot="car" slot-scope="car,record">
						<div v-if="car">
							<a-tag v-for="(c,ci) in car" :key="ci" color="green">{{c}}</a-tag>
						</div>
					</div>
					<!-- <div class="flex alcenter center" slot="integral" slot-scope="integral,record">
						{{integral==null ? '0' : integral.remain_integral}}
					</div> -->
			
			
					<template slot="action" slot-scope="record,index">
						<div class="flex center">
							<a class="menu-act" href="javascript:;" @click="changePid(record)">
								<span>修改上级用户</span>
							</a>
							<!-- <a-dropdown placement="bottomRight">
								<span class="more-act">
									<i class="iconfont iconmore_gray"></i>
								</span>
								<a-menu slot="overlay">
									<a-menu-item>
										<a class="menu-act" href="javascript:;" @click="edit(record)">
											<span>修改会员</span>
										</a>
									</a-menu-item>
									<a-menu-item>
										<a class="menu-act" href="javascript:;" @click="see(record)">
											<span>查看会员</span>
										</a>
									</a-menu-item>
									<a-menu-item>
										<a class="menu-act" href="javascript:;" @click="changePid(record)">
											<span>修改上级用户</span>
										</a>
									</a-menu-item>
								</a-menu>
							</a-dropdown> -->
						</div>
					</template>
				</a-table>
			</div>
		</div>
	
		<a-modal title="修改会员" :width="400" :visible="visible"  @cancel="handleCancel" >
			<template slot="footer" >
				<a-button  key="back"  @click="handleCancel">
					取消
				</a-button>
				<a-button  key="submit" type="primary" @click="onSubmit" :loading="confirmLoading">
					修改
				</a-button>
			</template>
			<div>
			<a-form-item label="会员昵称">
					<a-input placeholder="请输入会员姓名" v-model="member.nick_name" :maxLength="20"></a-input>
			</a-form-item>
			<a-form-item label="会员等级">
				<a-select style="width: 120px" placeholder="请选择" v-model="member.level_id">
					<a-select-option :value="-99">请选择</a-select-option>
					<!-- <a-select-option :value="0">普通会员</a-select-option> -->
					<a-select-option :value="item.level_id" v-for="(item,index) in level" :key="index">{{item.name}}</a-select-option>
				</a-select>
			</a-form-item>
			</div>
		</a-modal>
		
		<div v-if="changePidVisibel">
			<change-pid :visible="changePidVisibel" :member_id="member_id" @cancel="cancelChangePid" @ok="okChangePid"></change-pid>
		</div>
	</div>
</template>

<script>
	import {
		listMixin
	} from '../../common/mixin/list.js';
	import changePid from './components/index/modal/changPid.vue';
	export default {
		mixins: [listMixin],
		components:{
			changePid
		},
		data() {
			return {
				loading: false,
				visible:false,
				editloading:false,
				confirmLoading:false,
				changePidVisibel:false,
				pagination: {
					current: 1,
					pageSize: 10, //每页中显示10条数据
					total: 0,
				},
				search: {
					// nick_name: '',
					mobile: "",
					// level_id: -99,
					// birthday_type:1,
					carNum:''
				},
				level:[],
				columns: [
					{title: '会员编号',dataIndex: 'member_id',align: 'center'},
					{title: '会员',key: 'vip',align: 'center',scopedSlots: {customRender: 'vip'}},
					{title: '车辆',dataIndex: 'car',align: 'center',scopedSlots: {customRender: 'car'}},
					{title: '账户总余额',dataIndex: 'balance',align: 'center',scopedSlots: {customRender: 'balance'}},
					// {title: '上级用户',dataIndex: 'pmember',align: 'center',scopedSlots: {customRender: 'pmember'}},
					// {title: '会员等级',dataIndex: 'level',align: 'center',scopedSlots: {customRender: 'level'}},
					// {title: '会员生日',dataIndex: 'birthday',align: 'center',ellipsis: true},
					{title: '注册时间',dataIndex: 'add_time_format',align: 'center',ellipsis: true},
					{title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
				],
				datas: [],
				member:{},
				member_id: 0,
			}
		},
		methods: {
			getLists(){
				if(this.loading==true) return;
				this.loading=true;
				this.$http.api('admin/getMemberList',{
					// nick_name:this.search.nick_name,
					mobile:this.search.mobile,
					// level_id:this.search.level_id,
					// birthday_type:this.search.birthday_type,
					carNum:this.search.carNum,
					limit:this.pagination.pageSize,
					page:this.pagination.current,
				}).then(res=>{
					this.pagination.total=res.count;
					this.datas=res.res;
					this.level=res.level;
					this.loading=false;
				}).catch(res=>{
					console.log(res);
					this.loading=false;
				})
			},
			
			handleTableChange(pagination, filters, sorter) {
				this.pagination.current = pagination.current;
				this.getLists();
			},
			see(record) {
				console.log(record);
				this.$router.push('/member/detail?id=' + record.member_id);
			},
			edit(record){
				this.member=record;
				this.visible=true;
			},
			handleCancel(){
				this.visible=false;
			},
			onSubmit(){
				if(this.confirmLoading==true) return;
				this.confirmLoading=true;
				this.$http.api('admin/saveNickName',{
					member_id:this.member.member_id,
					// nick_name:this.member.nick_name,
					level_id :this.member.level_id,
				}).then(res=>{
					this.$message.success('修改成功',1,()=>{
						this.confirmLoading=false;
						this.visible=false;
						this.getLists();
					})
				}).catch(res=>{
					console.log(res);
					this.confirmLoading=false;
				})
			},
			
			exportMemberAct(){
				let href=process.env.VUE_APP_BASE_API + "/beautiful/admin/exportMember?auth_token="+encodeURIComponent(localStorage.getItem('beautiful-token'));
				if(this.search.nick_name != ''){
					 href=href+'&nick_name='+encodeURIComponent(this.search.nick_name);
				}
				if(this.search.mobile != ''){
					href=href+'&mobile='+encodeURIComponent(this.search.mobile);
				}
				href=href+'&level_id='+encodeURIComponent(this.search.level_id) ;
				href=href+'&birthday_type='+encodeURIComponent(this.search.birthday_type);
				href=href+'&page='+encodeURIComponent(this.pagination.current);
				window.location.href=href;
			},
			
			/**
			 * 修改用户的上级
			 * @param {Object} record
			 */
			changePid(record){
				this.member_id=record.member_id;
				this.changePidVisibel=true;
			},
			cancelChangePid(){
				this.changePidVisibel=false;
			},
			okChangePid(){
				this.changePidVisibel=false;
				this.getLists();
			},
		}
	}
</script>

<style>
	.member-index-face {
		width: 44px;
		height: 44px;
		border-radius: 44px;
		box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.04);
		border: 2px solid #FFFFFF;
	}

	.member-index-action {
		width: 32px;
		height: 32px;
		border-radius: 32px;
		background: #FFFFFF;
		border: 1px solid #EBEDF5;
		text-align: center;
		line-height: 32px;
	}
</style>
